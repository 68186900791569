import request from '@/utils/request'

// 添加用户
export function userAdd(data) {
  return request({
    url: 'api/v2/cjt/userAdd',
    method: 'post',
    data
  })
}

//用户列表
export function getCjtUserList(data) {
  return request({
    url: 'api/v2/cjt/getCjtUserList',
    method: 'post',
    data
  })
}

//申请
export function applyLoad(data) {
  return request({
    url: 'api/v2/cjt/applyLoad',
    method: 'post',
    data
  })
}

//产品list
export function getProductInfo(data) {
  return request({
    url: 'api/v2/cjt/getProductInfo',
    method: 'post',
    data
  })
}

//订单list
export function getOrderList(data) {
  return request({
    url: 'api/v2/cjt/getOrderList',
    method: 'post',
    data
  })
}

//订单详情
export function getOrderInfo(data) {
  return request({
    url: 'api/v2/cjt/getOrderInfo',
    method: 'post',
    data
  })
}

// 刷新订单状态
export function refreshOrderState(data) {
  return request({
    url: 'api/v2/cjt/refreshOrderState',
    method: 'post',
    data
  })
}

//org认证
export function tenantApply(data) {
  return request({
    url: 'api/v2/cjt/tenantApply',
    method: 'post',
    data
  })
}


//平台审核
export function adminAudit(data) {
  return request({
    url: 'api/v2/cjt/adminAudit',
    method: 'post',
    data
  })
}

//订单列表
export function getOrders(data) {
  return request({
    url: 'api/v2/cjt/getOrders',
    method: 'post',
    data
  })
}

//申请列表
export function getAllUser(data) {
  return request({
    url: 'api/v2/cjt/getAllUser',
    method: 'post',
    data
  })
}

//公司信息模糊查询
export function findCjtComInfo(data) {
  return request({
    url: 'api/v2/cjt/findCjtComInfo',
    method: 'post',
    data
  })
}