<template>
  <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card" v-if="!isCheck">
    <el-tab-pane label="产品列表" name="1">
      <div @click="checkShStatus" v-if="!productStatus" >
        <el-card class="product">
          <div>
            <img src="../../assets/dk.png" alt="">
          </div>
          <p>助贷平台</p>
        </el-card>
      </div>
      <div v-else v-loading="productLoading">
        <el-scrollbar :height="contentStyleObj">
          <el-card style="position: relative;">
            <div style="position: absolute;top:20px;right: 20px;">
              <el-button icon="Back" size="small" @click="backTop">
                返回
              </el-button>
            </div>
            <div style="display: flex;align-items: center;font-size: 14px;">
              <div>产品类型：</div>
              <div class="tab">
                <span v-for="(item, index) in productType" :key="index" :class="{ active: index === activeTypeIndex }" @click="changeTypeTab(item,index)">{{ item.label }}</span>
              </div>
            </div>
            <div style="display: flex;align-items: center;font-size: 14px;margin-top: 20px;">
              <div>产品名称：</div>
              <div class="tab">
                <span v-for="(item, index) in productNameOption" :key="index" :class="{ active: index === activeNameIndex }" @click="changeNameTab(item,index)">{{ item.product_name }}</span>
              </div>
            </div>
          </el-card>
          <div v-if="productList.length != 0">
            <div class="list_main" v-for="(item, index) in productList" :key="index">
              <div class="each">
                <div style="color: #000;font-weight: 550;">{{ item.product_name }}</div>
              </div>
              <div class="each">
                <div class="title">额度（万）</div>
                <div style="color: #000;font-weight: 550;">{{ item.max_amount }}</div>
              </div>
              <div class="each">
                <div class="title">期限（月）</div>
                <div style="color: #ff0100;font-weight: 550;">{{ item.apply_term }}</div>
              </div>
              <div class="each" style="width: 40%;">
                <div class="title">开放区域</div>
                <div class="area">
                  <el-tooltip effect="light" placement="top-start">
                    <template #content>
                      <div v-html="(item.open_region).replace(/\\n/g, '<br/>')" style="max-width: 500px"></div>
                    </template>
                    {{ (item.open_region).replace(/\\n/g, '，') }}
                  </el-tooltip>
                </div>
              </div>
              <div class="each">
                <el-button type="danger" @click="apply(item)" >立即申请</el-button>
              </div>
            </div>
          </div>
          <div v-else>
            <el-empty description="暂无数据"></el-empty>
          </div>
        </el-scrollbar>
      </div>
    </el-tab-pane>
    <el-tab-pane label="商户管理" name="2">
      <div class="filter_border">
        <el-tabs v-model="activeName1" @tab-click="handleClick1">
          <el-tab-pane label="全部" name="1"></el-tab-pane>
          <el-tab-pane label="进件中" name="applying"></el-tab-pane>
          <el-tab-pane label="审核中" name="audit"></el-tab-pane>
          <el-tab-pane label="审核通过" name="auditPass"></el-tab-pane>
          <el-tab-pane label="审核拒绝" name="auditRefuse"></el-tab-pane>
        </el-tabs>
      </div>
      <div style="position: relative;">
        <el-collapse v-model="activeNames">
        <el-collapse-item title="筛选查询" name="1">
          <el-form size="small" label-width="120px" :inline="true" class="form_style">
            <div>
              <el-form-item label="贷款产品">
                <el-select placeholder="请选择贷款产品" filterable clearable style="width:140px;margin: 0;" v-model="listQuery.productName">
                  <el-option v-for="item in productOption" :key="item.product_name" :label="item.product_name" :value="item.product_name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="申请时间">
                <el-date-picker v-model="listQuery.applyTime" type="date" value-format="YYYYMMDD" placeholder="请选择申请时间" style="width: 140px;" clearable></el-date-picker>
              </el-form-item>
              <el-form-item label="申请人姓名">
                <el-input style="width:140px" v-model="listQuery.userName" placeholder="请输入申请人姓名" clearable></el-input>
              </el-form-item>
              <el-form-item label="申请人手机号">
              <el-input style="width:140px" v-model="listQuery.userMobile" placeholder="请输入申请人手机号" clearable></el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="企业名称">
                <el-input style="width:140px" v-model="listQuery.companyName" placeholder="请输入企业名称" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="getList()">查询</el-button>
              </el-form-item>
              <!-- <el-form-item label="企业地区">
                <el-input style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="是否补充资料">
                <el-input style="width:140px"></el-input>
              </el-form-item> -->
            </div>
           
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <div style="position: absolute;bottom: 5px;right: 0;">
        <el-button type="primary" @click="batchFreshOrder()" size="small" plain icon="Refresh">刷新订单</el-button>
      </div>
      </div>
      <el-table :data="list" style="width: 100%" border :height="contentStyleObj1" @selection-change="handleSelectionChange" v-loading="loading">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column type="index" label="序号" width="55" align="center" />
        <el-table-column align="left" label="产品名称" min-width="130">
          <template #default="scope">
            <span >{{ scope.row.productName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="公司名称" min-width="240">
          <template #default="scope">
            <div style="display: flex;align-items: center;">
              <div class="item_icon" style="width: 95%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                <span >{{ scope.row.companyName }}</span>
            </div>
            <el-tooltip content="复制" placement="top" effect="dark">
              <i class="iconfont icon-fuzhi1" style="color:var(--themeColor,#17a2b8);cursor: pointer;" @click="$copyComName(scope.row.companyName)">
              </i>
            </el-tooltip>
          </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="申请人姓名" width="100">
          <template #default="scope">
            <span >{{ scope.row.userName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="申请人手机号" width="120">
          <template #default="scope">
            <span >{{ scope.row.userMobile }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="申请时间" width="150">
          <template #default="scope">
            <span >{{ scope.row.applyTime }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="申请额度" width="90">
          <template #default="scope">
            <span >{{ scope.row.applyAmount }}万</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="申请期限" width="90">
          <template #default="scope">
            <span >{{ scope.row.applyTerm }}月</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="订单状态" width="100">
          <template #default="scope">
            <span v-if="scope.row.orderStatus == 'applying'">进件中</span>
            <span v-else-if="scope.row.orderStatus == 'cancel'">已撤销</span>
            <span v-else-if="scope.row.orderStatus == 'audit'">审核中</span>
            <span v-else-if="scope.row.orderStatus == 'audit'">审核中</span>
            <span v-else-if="scope.row.orderStatus == 'auditPass'">审核通过</span>
            <el-tooltip v-else-if="scope.row.orderStatus == 'auditRefuse'" :disabled="!scope.row.refuseReason" effect="dark" :content="scope.row.refuseReason" placement="top-start">
              <span style="cursor: pointer;color: red;">审核拒绝<i class="iconfont icon-wenhao" v-if="scope.row.refuseReason" style="margin-left: 5px;color: red;font-size: 13px;"></i></span>
            </el-tooltip>
            <span v-else-if="scope.row.orderStatus == 'loan'">已放款</span>
            <span v-else-if="scope.row.orderStatus == 'overdue'">逾期</span>
            <span v-else-if="scope.row.orderStatus == 'settle'">结清</span>
            <span v-else-if="scope.row.orderStatus == 'loanFail'">放款失败</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" min-width="180">
          <template #default="scope">
            <el-button type="primary" @click="freshOrder(scope.row)" size="small" plain icon="Refresh">刷新订单</el-button>
            <el-button type="primary" @click="checkOrder(scope.row)" size="small" plain icon="Document" :disabled="!scope.row.loanList">放款详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
  </el-tabs>
  <div v-else class="main">
    <div class="main_left">
      <img src="../../assets/qywx.png" />
    </div>
    <div class="main_right">
      <h3>
       企业未认证
      </h3>
      <h5>助贷平台功能亮点</h5>
      <div class="each_content">
        <div>
          <el-icon>
            <circle-check-filled />
          </el-icon>
        </div>
        <div>
          <p>到账快</p>
          <p>极速审批下款</p>
        </div>
      </div>
      <div class="each_content">
        <div>
          <el-icon>
            <circle-check-filled />
          </el-icon>
        </div>
        <div>
          <p>额度高</p>
          <p>上限300万</p>
        </div>
      </div>
      <div class="each_content">
        <div>
          <el-icon>
            <circle-check-filled />
          </el-icon>
        </div>
        <div>
          <p>无压力</p>
          <p>年化低至5%</p>
        </div>
      </div>
      <div class="each_content">
        <div>
          <el-icon>
            <circle-check-filled />
          </el-icon>
        </div>
        <div>
          <p>申请易</p>
          <p>
            全线上办理
          </p>
        </div>
      </div>
      <div>
        <el-button type="primary" style="padding:5px 28px" @click="comRz"
          >企业认证<el-icon style="margin-left: 3px;">
            <success-filled />
          </el-icon>
        </el-button>
      </div>
    </div>
    <div class="pic">
      <img src="../../assets/qywxtop.png" />
    </div>
  </div>
  <!-- 产品申请查看详情 -->
  <el-drawer
    class="elDrawerDetail"
    :title="productInfo.product_name"
    v-model="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    size="75%"
  >
    <el-card>
      <div class="card">
        <div class="card_each">
          <div class="card_info">{{ productInfo.max_amount }}</div>
          <div class="card_title">产品额度</div>
        </div>
        <div class="card_each">
          <div class="card_info">{{ productInfo.apply_term }}</div>
          <div class="card_title">还款期限</div>
        </div>
        <div class="card_each">
          <div class="card_info">{{ productInfo.mode_repayment }}</div>
          <div class="card_title">还款方式</div>
        </div>
        <div class="card_each" style="width: 31%;text-align: center;">
          <div style="margin-bottom: 5px;">
            <el-button type="danger" @click="login">立即申请</el-button>
          </div>
          <div style="color: #ff4d4d;font-size: 12px;">
            *实际借款金额以金融机构实际审批结果为准
          </div>
        </div>
      </div>
    </el-card>
    <el-row>
      <el-col :span="4">
        产品要素
      </el-col>
      <el-col :span="20">
        年化利率： {{ productInfo.rate }} &nbsp;&nbsp; 贷款期限：{{ productInfo.apply_term }}  &nbsp;&nbsp; 额度（万）：{{ productInfo.max_amount }}
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">
        申请条件
      </el-col>
      <el-col :span="20">
        <p>一、申请人准入条件：</p>
        <div v-html="(productInfo.applicat_condition).replace(/\\n/g,'<br>')"></div>
        <p>二、企业要求：</p>
        <div v-html="(productInfo.high_clientele).replace(/\\n/g,'<br>')"></div>
        <p>三、征信要求：</p>
        <div v-html="(productInfo.credit_requirement).replace(/\\n/g,'<br>')"></div>
        <p>四、数据要求：</p>
        <div v-html="(productInfo.billing_requirement).replace(/\\n/g,'<br>')"></div>
        <p>五、禁入行业：</p>
        <div v-html="(productInfo.forbid_business).replace(/\\n/g,'<br>')"></div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">
        适用地区
      </el-col>
      <el-col :span="20">
        <div v-html="(productInfo.open_region).replace(/\\n/g, '<br/>')"></div>
      </el-col>
    </el-row>
  </el-drawer>
  <!-- 申请人信息绑定 -->
  <el-dialog v-model="dialogLoginVisible" title="申请" width="500px" center destroy-on-close :close-on-click-modal="false">
    <el-form :model="form" label-width="160px" :rules="rules" ref="dataForm">
      <el-form-item label="企业名称" prop="companyName">
        <el-autocomplete style="width:200px;" v-model="form.companyName" valueKey="comName" :fetch-suggestions="querySearch" @select="handleSelect" placeholder="请输入企业名称"></el-autocomplete>
      </el-form-item>
      <el-form-item label="统一社会信用代码" prop="companyCreditCode">
        <el-input v-model="form.companyCreditCode" style="width:200px" placeholder="请输入统一社会信用代码"></el-input>
      </el-form-item>
      <el-form-item label="申请人姓名" prop="userName">
        <el-input v-model="form.userName" style="width:200px" placeholder="请输入申请人姓名"></el-input>
      </el-form-item>
      <el-form-item label="申请人手机号" prop="userMobile">
        <el-input v-model="form.userMobile" style="width:200px" placeholder="请输入申请人手机号"></el-input>
      </el-form-item>
      <el-form-item label="申请人身份证号" prop="IdCardNo">
        <el-input v-model="form.IdCardNo" style="width:200px" placeholder="请输入申请人身份证号"></el-input>
      </el-form-item>
      <el-form-item label="申请额度（万）" prop="applyAmount">
        <el-input v-model="form.applyAmount" style="width:200px" placeholder="请输入申请额度"></el-input>
      </el-form-item>
      <el-form-item label="申请期限（月）" prop="applyTerm">
        <el-input v-model="form.applyTerm" style="width:200px" placeholder="请输入申请期限"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="bindSure" >提 交</el-button>
        <el-button @click="dialogLoginVisible = false" >取 消</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 审核信息 -->
  <el-dialog v-model="dialogRzVisible" title="企业认证" width="500px" center destroy-on-close :close-on-click-modal="false">
   <el-form :model="authForm" :rules="authRules" ref="authForm" label-width="160px">
    <el-form-item label="申请人姓名" prop="cnName">
      <el-input style="width: 200px;" placeholder="请输入申请人姓名" v-model="authForm.cnName"></el-input>
    </el-form-item>
    <el-form-item label="申请人手机号" prop="phone">
      <el-input style="width: 200px;" placeholder="请输入申请人手机号" v-model="authForm.phone"></el-input>
    </el-form-item>
    <el-form-item label="申请人身份证号" prop="idNo">
      <el-input style="width: 200px;" placeholder="请输入申请人身份证号" v-model="authForm.idNo"></el-input>
    </el-form-item>
    <el-form-item label="企业名称" prop="comName">
      <el-input style="width: 200px;" placeholder="请输入企业名称" v-model="authForm.comName"></el-input>
    </el-form-item>
    <el-form-item label="社会统一信用代码" prop="taxNo">
      <el-input style="width: 200px;" placeholder="请输入社会统一信用代码" v-model="authForm.taxNo"></el-input>
    </el-form-item>
   </el-form>
   <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="authSure">提 交</el-button>
        <el-button @click="dialogRzVisible = false">取 消</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 放款list -->
  <el-dialog v-model="dialogFkVisible" title="放款信息" width="800px" destroy-on-close :close-on-click-modal="false">
    <el-table :data="orderInfo.loanList" style="width: 100%" border :height="contentStyleObj1">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="index" label="序号" width="55" align="center" />
      <el-table-column align="left" label="放款时间" min-width="120">
        <template #default="scope">
          <span >{{ scope.row.loanTime }}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="放款金额" min-width="100">
        <template #default="scope">
          <span >{{ scope.row.loanAmount }}万</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="年化利率" min-width="90">
        <template #default="scope">
          <span >{{ scope.row.loanLentRate }}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="还款状态" min-width="110">
        <template #default="scope">
          <span v-if="scope.row.loanStatus == 'loan'">已放款</span>
          <span v-else-if="scope.row.loanStatus == 'repay'">还款中</span>
          <span v-else-if="scope.row.loanStatus == 'overdue'">逾期</span>
          <span v-else-if="scope.row.loanStatus == 'settle'">结清</span>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="dialogFkVisible = false" size="small">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {getCjtUserList,userAdd,getProductInfo,applyLoad,refreshOrderState,getOrderInfo ,tenantApply,findCjtComInfo,getOrders} from "@/api/cjt"
export default {
  name: "",
  components: {},
  props: {},
  data() {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入申请人手机号"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        return callback();
      }
    };
    let validateIdNo = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入申请人身份证号"));
      }
      if (!/^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/.test(value)) {
        callback(new Error("请输入正确的身份证号"));
      } else {
        return callback();
      }
    };
    return {
      list: [],
      total:0,
      loading:false,
      contentStyleObj: {},
      contentStyleObj1:{},
      drawer: false,
      dialogLoginVisible: false,
      activeName: "1",
      active: true,
      listQuery: {
        // page: 1,
        // limit: 20,
      },
      temp:{
        comId:null
      },
      form:{
        comId:null
      },
      authForm:{
        comName:"",
        cnName:"",
        phone:"",
        idNo:"",
        taxNo:""
      },
      dialogProductVisible:false,
      productList:[],
      productInfo:{},
      addLoading:false,
      rules:{
        companyName:[{required:true,message:'请输入企业名称',trigger:'blur'}],
        companyCreditCode:[{required:true,message:'请输入统一社会信用代码',trigger:'blur'}],
        userName:[{required:true,message:'请输入申请人姓名',trigger:'blur'}],
        userMobile:[{required:true,validator: validateMobile,trigger:'blur'}],
        IdCardNo:[{required:true,validator: validateIdNo,trigger:'blur'}],
        applyAmount:[{required:true,message:'请输入申请额度',trigger:'blur'}],
        applyTerm:[{required:true,message:'请输入申请期限',trigger:'blur'}]
      },
      orderInfo:{},
      status:this.$store.getters['user/user'].audit,
      auditCause:this.$store.getters['user/user'].auditCause,
      productStatus:0,
      isCheck:false,
      activeName1:"1",
      dialogRzVisible:false,
      authRules:{
        cnName:[{required:true,message:'请输入申请人姓名',trigger:'blur'}],
        phone:[{required:true,validator: validateMobile,trigger:'blur'}],
        idNo:[{required:true,validator: validateIdNo,trigger:'blur'}],
        comName:[{required:true,message:'请输入企业名称',trigger:'blur'}],
        taxNo:[{required:true,message:'请输入社会统一信用代码',trigger:'blur'}]
      },
      productLoading:false,
      activeNames:['1'],
      productType:[{label:'全部',value:''},{label:'会计数据贷',value:'会计数据贷'},{label:'ERP贷',value:'ERP贷'},{label:'银税贷',value:'银税贷'}],
      activeTypeIndex:0,
      productNameOption:[],
      activeNameIndex:0,
      listQuery1:{
        productName:"",
        productType:""
      },
      productOption:[],
      sels:[],
      dialogFkVisible:false
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(210);
    this.contentStyleObj1 = this.$getHeight(353)
    this.init()
    this.getList()
  },
  methods: {
    init(){
      getProductInfo({}).then(res=>{
        if(res.data.msg == 'success'){
          let arr = []
          this.productNameOption = arr.concat([{product_name:'全部'}],res.data.data)
          this.productOption = res.data.data
        }
      })
    },
    getList(){
      this.loading = true
      getOrders(this.listQuery).then(res=>{
        this.loading = false
        if(res.data.msg == 'success'){
          this.list = res.data.data.list ? res.data.data.list : []
          this.total = res.data.data.total
        }
      })
    },
    //多选
    handleSelectionChange(e){
      this.sels = e
    },
    //获取产品信息
    getProduct(){
      this.productLoading = true
      getProductInfo(this.listQuery1).then(res=>{
        this.productLoading = false
        if(res.data.msg == 'success'){
          this.productList = res.data.data ? res.data.data : []
        }
      })
    },
    apply(item) {
      this.productInfo = item
      this.drawer = true;
    },
    login() {
      this.form.productId = this.productInfo.product_id
      this.dialogLoginVisible = true;
    },
    bindSure(){
      this.$refs["dataForm"].validate((valid) => {
        if(valid){
          this.form.applyAmount = Number(this.form.applyAmount)
          this.form.applyTerm = Number(this.form.applyTerm)
          applyLoad(this.form).then(res=>{
            if(res.data.msg == 'success'){
              this.$qzfMessage('申请成功')
              this.dialogLoginVisible = false
              this.getList()
              window.open(res.data.data)
            }
          })
        }
      })
    },
    //查看订单详情
    checkOrder(row){
      this.orderInfo = row
      this.dialogFkVisible = true
    },
    comRz(){
     this.dialogRzVisible = true
    },
    //审核
    checkShStatus(){
      if(!this.status){
        this.isCheck = true
      }else if(this.status == 1){
       this.$qzfMessage('认证账号正在审核中，请等待',1)
      }else if(this.status == 2){
        this.productStatus = 1
        this.getProduct()
      }else{
        if(this.auditCause){
          this.$qzfMessage(this.auditCause,1)
        }else{
          this.$qzfMessage('认证账号审核未通过，请重新认证',1)
        }
        this.isCheck = true
      }
    },
    //返回产品列表
    backTop(){
      this.isCheck = false
      this.productStatus = 0
    },
    changeTypeTab(row,i){
      this.activeTypeIndex = i
      this.listQuery1.productType = row.value
      this.getProduct()
    },
    changeNameTab(row,i){
      this.activeNameIndex = i
      if(row.product_name == '全部'){
        this.listQuery1.productName = ""
      }else{
        this.listQuery1.productName = row.product_name
      }
      this.getProduct()
    },
    authSure(){
      this.$refs["authForm"].validate((valid) => {
        if(valid){
          tenantApply(this.authForm).then(res=>{
            if(res.data.msg == 'success'){
              this.$qzfMessage('已提交认证,请等待')
              this.dialogRzVisible = false
              this.status = 1
            }
          })
        }
      })
    },
    //公司信息模糊查询
    querySearch(queryString, cb){
      if(queryString == "" || queryString.length < 4){
        return
      }
      let params = {
        comName:queryString
      }
      findCjtComInfo(params).then(res=>{
        if(res.data.msg == 'success'){
          let results = res.data.data
          if(results){
            cb(results);
          }
        }
      })
    },
    handleSelect(item) {
      this.form.companyCreditCode = item.taxNo
      this.form.userName = item.cnName
      this.form.userMobile = item.phone
      this.form.IdCardNo = item.idNo
    },
    //
    handleClick(tab){
      if(tab.props.name == '2'){
        this.getList()
      }
    },
    handleClick1(tab){
      if(tab.props.name == '1'){
        this.listQuery.orderStatus = ''
      }else{
        this.listQuery.orderStatus = tab.props.name
      }
      this.getList()
    },
    //刷新订单状态
    freshOrder(row){
      refreshOrderState({ids:[row.id]}).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage('刷新成功')
          this.getList()
        }
      })
    },
    batchFreshOrder(){
      if(this.sels.length == 0){
        this.$qzfMessage('请选择订单',1)
        return
      }
      let ids = []
      this.sels.map(v=>{
        ids.push(v.id)
      })
      refreshOrderState({ids}).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage('刷新成功')
          this.getList()
        }
      })
    },
  },
};
</script>

<style scoped lang="scss">
.top_select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.box {
  width: 95%;
  margin: 0 auto;
}
.list_main {
  padding-bottom: 10px;
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
  .each {
    width: 15%;
    margin-top: 20px;
    text-align: center;
  }
  .title {
    font-size: 13px;
    color: #999;
    padding-bottom: 10px;
  }
  .area {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
}

.product{
  width: 100px;
  text-align: center;
  cursor: pointer;
  margin-left: 20px;
  img{
    width: 30px;
    height: 30px;
  }
  p{
    font-size: 14px;
  }
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card_each {
  width: 23%;
}
.card_info {
  color: #e60000;
  margin-bottom: 15px;
  font-size: 14px;
}
.card_title {
  color: #999;
  font-size: 14px;
}
.box {
  margin-top: 20px;
}
.el-col {
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 20px 32px;
  font-size: 14px;
}
.el-col:last-child {
  border-right: none;
}
.el-col-4 {
  text-align: center;
}
.tab {
  margin-left: 20px;
  font-size: 14px;
  span {
    transition: 0.3s;
    cursor: pointer;
    line-height: 22px;
    background-image: linear-gradient(#17a2b8, #17a2b8);
    background-position: bottom;
    background-size: 0 2px;
    background-repeat: no-repeat;
    padding-bottom: 5px;
    margin-right: 40px;
  }
  .active {
    color: #17a2b8;
    background-size: 100% 2px;
  }
}
.div_p {
  line-height: 30px;
  border-bottom: 1px solid #efefef;
  // text-indent: 0.5em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}

.main {
  width: 100%;
  height: calc(100vh - 96px);
  position: relative;
  display: flex;
  align-items: center;
}
.main_left {
  width: 45%;
  height: 100%;
  background-image: linear-gradient(to right, #eff9fb, #fefeff);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 90%;
  }
}
.main_right {
  float: left;
  h3 {
    margin-bottom: 15px;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 600;
  }
  h5 {
    margin-bottom: 10px;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 600;
  }
}
.pic {
  width: 150px;
  position: absolute;
  top: 0;
  right: 0;
  img {
    width: 100%;
  }
}
.each_content {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  .el-icon {
    font-size: 20px;
    color: #5aca8b;
    margin-right: 3px;
  }

  p {
    color: #474747;
    font-size: 14px;
  }
}
:deep(.el-collapse-item__content){
  padding-bottom: 0;
}
:deep(.el-collapse-item__header){
  height: 30px;
  line-height: 30px;
}
.form_style .el-form-item{
  margin-bottom: 5px;
}
.filter_border{
  border: 1px solid #efefef;
  border-radius: 4px;
  box-shadow: 0 2px 10px #ddd;
  padding: 0 20px;
}
</style>
